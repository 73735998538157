import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

import Img from "gatsby-image"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout title="บทความ">
        <SEO title="บทความ" />
        <Header siteTitle="บทความ" />
        <div className="container">
          <div className="pt-4"></div>
          <div className="row">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title
              const featuredImage = node.frontmatter.featuredImage
                ? node.frontmatter.featuredImage.childImageSharp.fluid
                : "../../assets/images/about-wallpaper.png"
              return (
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                  <article className="content-item -card">
                    <div className="pic">
                      <Link to={node.frontmatter.slug}>
                        <Img fluid={featuredImage} />
                      </Link>
                    </div>
                    <div className="info">
                      <header className="entry-header">
                        <h2 className="entry-title">
                          <Link to={node.frontmatter.slug}>{title}</Link>
                        </h2>
                      </header>
                    </div>
                  </article>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            slug
            date(formatString: "YYYY-MM-DD")
            title
            metaDescription
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
